import React from 'react'
import { MDXTag } from '@mdx-js/tag'


const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="p" components={components}>{`Im yan and I havent decided what to put on my about page`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {
  "title": "About",
  "slug": "about",
  "cover": "./mountains.jpg"
};
    